import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DYNAMICK_LINK } from 'src/constants';

export default function useAppURLsByDevice(
  sp = {
    appStoreUrl: 'https://paidy.onelink.me/GTiS/hppaidycard',
    playStoreUrl: 'https://paidy.onelink.me/GTiS/hppaidycard',
  },
  pc = {
    appStoreUrl: DYNAMICK_LINK.APP_STORE,
    playStoreUrl: DYNAMICK_LINK.PLAY_STORE,
  }
) {
  const isMobile = useMediaQuery('(max-width:600px)');

  if (isMobile) {
    return {
      appStoreUrl: sp.appStoreUrl,
      playStoreUrl: sp.playStoreUrl,
    };
  }

  return {
    appStoreUrl: pc.appStoreUrl,
    playStoreUrl: pc.playStoreUrl,
  };
}
