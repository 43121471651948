// @flow
import { Box } from '@material-ui/core';
import React from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Iphone13Payment from 'src/components/Apple/Iphone13Payment';
import Benefits from 'src/components/Apple/Benefits';

import { SITE_METADATA, APPLE_LINKS } from '../../constants';
import { getOgData } from '../../utils';
import AppDownload from '../../components/AppDownload/AppDownload';
import AppDownloadQR from '../../components/AppDownloadQR/AppDownloadQR';
import Layout from '../../components/Layout/Layout';
import Features from '../../components/Apple/Features';
import Steps from '../../components/Apple/Steps';
import Conditions from '../../components/Apple/Conditions';
import Questions from '../../components/Apple/Questions';
import styles from '../../styles/pages/apple.module.scss';

const ApplePage = () => {
  const appDownloadText = (
    <>
      ペイディアプリを
      <br />
      ダウンロードして申し込む
    </>
  );

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.apple.title,
        description: SITE_METADATA.apple.description,
      }}
    >
      <Helmet
        title={SITE_METADATA.apple.title}
        meta={getOgData({
          title: SITE_METADATA.apple.title,
          description: SITE_METADATA.apple.description,
          siteMetadata: SITE_METADATA.apple,
        })}
      >
        <meta name="description" content={SITE_METADATA.apple.description} />
      </Helmet>
      <div className={styles.heroWrapper}>
        <div className={styles.banner}></div>
      </div>
      <Box className={styles.intro}>
        <div className={styles.introTextWrapper}>
          <h1>
            ペイディあと払いプランApple専用は、
            <br />
            Apple製品を手に入れる最高の方法です。
          </h1>
        </div>
        <div>
          <p>
            ペイディあと払いプランApple専用は、Appleでの購入専用の特別なご利用可能額をご用意。一度登録を完了するだけで、
            <OutboundLink
              href={APPLE_LINKS.WEBSITE}
              target="_blank"
              rel="noreferrer"
            >
              Appleのウェブサイト
            </OutboundLink>
            、または{' '}
            <OutboundLink
              href={APPLE_LINKS.STORE}
              target="_blank"
              rel="noreferrer"
            >
              お近くのApple Store
            </OutboundLink>
            で何度でもご利用可能です。お申し込みは簡単、最短5分で完了します。iPhoneやMac、iPad、Apple
            Watch、AppleCare、アクセサリなど、Appleで製品を最も賢く購入する方法のひとつです。
          </p>

          <Link className={styles.link} to="/apple#benefits">
            iPhoneの購入もAppleで
            <img
              src={require('../../images/pink-right-arrow.svg')}
              alt="pink-right-arrow"
              height={11}
              width={8}
            />
          </Link>
        </div>
      </Box>
      <div className={styles.sp}>
        <AppDownload text={appDownloadText} clickArea="apple_page_first" />
      </div>
      <div className={styles.pc}>
        <AppDownloadQR
          text={appDownloadText}
          QRImg={require('../../images/qr-code-apple.png')}
          clickArea="apple_page_first"
        />
      </div>
      <Features />
      <Steps
        title={
          <>
            登録は5分で完了。
            <br />
            たったの3ステップ。
          </>
        }
        steps={[
          {
            image: require('../../images/apple-steps-1.svg'),
            info:
              'ペイディアプリから「ペイディあと払いプランApple専用」に申し込む。',
          },
          {
            image: require('../../images/apple-steps-2.svg'),
            info:
              'ご自分の「ペイディあと払いプランApple専用」のご利用可能額を確認する。',
          },
          {
            image: require('../../images/apple-steps-3.svg'),
            info:
              'Appleで「あと払い（ペイディ）」 を利用してお支払いください。',
          },
        ]}
        containerStyle={{
          paddingLeft: 32,
          paddingRight: 32,
        }}
        className={styles.steps}
      />
      <div className={styles.sp}>
        <AppDownload text={appDownloadText} clickArea="apple_page_second" />
      </div>
      <div className={styles.pc}>
        <AppDownloadQR
          text={appDownloadText}
          QRImg={require('../../images/qr-code-apple.png')}
          clickArea="apple_page_second"
        />
      </div>
      <Conditions />
      <Iphone13Payment />
      <Benefits />
      <div className={styles.sp}>
        <AppDownload text={appDownloadText} clickArea="apple_page_third" />
      </div>
      <div className={styles.pc}>
        <AppDownloadQR
          text={appDownloadText}
          QRImg={require('../../images/qr-code-apple.png')}
          clickArea="apple_page_third"
        />
      </div>
      <Questions />
    </Layout>
  );
};

// $FlowFixMe
export default ApplePage;
