import React from 'react';
import { Container } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import PinkLeftArrowIcon from 'src/images/pink-left-arrow.svg';
import styles from './Iphone13Payment.module.scss';

const SHOW_TABLE = false;

export default function Iphone13Payment() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <h3 className={styles.title}>
          最新のiPhone を36回払い、分割手数料0%*で。
        </h3>
        <div className={styles.content}>
          <p className={styles.description}>
            ペイディあと払いプランApple専用なら、Appleのウェブサイトやお近くのApple
            Storeで、最新のiPhone を36回払い、分割手数料0％*で購入できます。
          </p>

          {SHOW_TABLE && (
            <>
              <table className={styles.table} cellSpacing={16}>
                <colgroup>
                  <col span="1" className={styles.col1} />
                  <col span="1" className={styles.col2} />
                  <col span="1" className={styles.col3} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      新しい
                      <br />
                      iPhone
                    </th>
                    <th>下取り</th>
                    <th>
                      月々の
                      <br />
                      お支払い
                    </th>
                  </tr>
                  <tr>
                    <th>
                      iPhone 14 <br />
                      Pro Max
                    </th>
                    <td>
                      iPhone 12 <br />
                      Pro Max
                    </td>
                    <td>
                      3,050円/月
                      <br />
                      から**
                    </td>
                  </tr>
                  <tr>
                    <th>
                      iPhone 14 <br />
                      Pro
                    </th>
                    <td>
                      iPhone 12 <br />
                      Pro
                    </td>
                    <td>
                      2,855円/月
                      <br />
                      から**
                    </td>
                  </tr>
                  <tr>
                    <th>
                      iPhone 14 <br />
                      Plus
                    </th>
                    <td>iPhone 12 </td>
                    <td>
                      2,772円/月 <br />
                      から**
                    </td>
                  </tr>
                  <tr>
                    <th>iPhone 14</th>
                    <td>iPhone 12</td>
                    <td>
                      2,355円/月 <br />
                      から**
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <p className={styles.tableDescription}>
            さらに24か月目に新しいiPhoneに買い替えると、残額のお支払いが不要に**。
            <br />
          </p>

          <OutboundLink
            className={styles.appleLink}
            href="https://www.apple.com/jp/shop/buy-iphone/iphone-16?cid=aos-jp-aff-bp-paidy-web-benefits-event "
            rel="noopener noreferrer"
            target="_blank"
          >
            詳しくはこちら
            <img
              alt="icon"
              src={PinkLeftArrowIcon}
              className={styles.pinkLeftArrowIcon}
            />
          </OutboundLink>

          <small className={styles.tableSmall}>
            * 口座振替・銀行振込のみ0％。
            <br />
            **条件あり
          </small>
        </div>
      </Container>
    </section>
  );
}
