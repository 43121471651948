// @flow
import React from 'react';
import useAppURLsByDevice from 'src/hooks/useAppUrlsByDevice';
import cx from 'classnames';
import BoxIndex from '../BoxIndex/BoxIndex';
import styles from './AppDownloadQR.module.scss';
import { trackAppDownload } from '../../utils';

const SP_URLS = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/applenew',
  playStoreUrl: 'https://paidy.onelink.me/GTiS/applenew',
};

// clickArea is passed into GA to identify which button was pressed
const AppDownloadQR = ({
  text,
  QRImg = require('../../images/qr-code-home.png'),
  isGray = true,
  smallButtons = false,
  clickArea,
  ...props
}) => {
  const { appStoreUrl, playStoreUrl } = useAppURLsByDevice(SP_URLS);

  const buttonSize = smallButtons
    ? {
        width: 127,
        height: 38,
      }
    : {
        width: 140,
        heigth: 42,
      };

  return (
    <BoxIndex anchorId="app_download_qr" title="" isGray={isGray} {...props}>
      <div className={styles.appDownload}>
        <div className={styles.qrWrapper}>
          <img
            className={styles.qrCode}
            src={QRImg}
            alt="QR Code"
            loading="lazy"
          />
        </div>
        <div className={styles.content}>
          <h3 className={cx(smallButtons && styles.bigger)}>
            {text || (
              <>
                ペイディアプリでお買い物を
                <br />
                もっと便利に。もっと楽しく。
              </>
            )}
          </h3>
          <nav>
            <a
              className=""
              href={appStoreUrl}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => trackAppDownload('App Store', clickArea)}
            >
              <img
                src={require('../../images/badge-app-store.png')}
                alt="Apple App Store - Paidy"
                {...buttonSize}
                loading="lazy"
              />
            </a>
            <a
              className=""
              href={playStoreUrl}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => trackAppDownload('Google Play', clickArea)}
            >
              <img
                src={require('../../images/badge-play-store.png')}
                alt="Google Play Store - Paidy"
                {...buttonSize}
                loading="lazy"
              />
            </a>
          </nav>
        </div>
      </div>
    </BoxIndex>
  );
};

export default AppDownloadQR;
